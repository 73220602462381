import quake from "./quake.jpg";

function App() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
        backgroundImage: `url(${quake})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        color: "white",
        textAlign: "center",
        fontSize: "24px",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <div
        style={{
          backgroundColor: "rgba(128, 128, 128, 0.5)",
          padding: "20px",
          borderRadius: "8px",
        }}
      >
        QuakeWorld Duel Ranks is under construction and will be back shortly ...
      </div>
    </div>
  );
}

export default App;
